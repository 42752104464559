import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PanelProps } from '@grafana/data';

import { SimpleOptions } from 'types';

import {  Button, Input, useTheme2, CustomScrollbar, ConfirmModal } from '@grafana/ui';

import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, ThemeProvider, createTheme } from '@material-ui/core';



interface Props extends PanelProps<SimpleOptions> {}





interface VematDevicesTable{
  id: number;
  name: string;
  coef: number | null;
  edited: boolean;
  showError: boolean;
  [key: string]: any;
}
interface Ierror {
  id: number;
  message: string;
  show: boolean;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  //console.log(data.request?.targets[0].datasource?.uid);
  //const styles = useStyles2(getStyles);
  const theme = useTheme2();

  //const [errors, setErrors] = useState<Error[]>([]);

  //const [editingError, setEditingError] = useState<number | null>(null);
  //const [editedMessage, setEditedMessage] = useState<string>('');
  //const [newErrorMessage, setNewErrorMessage] = useState('');
  //The first value,  is our current state.The second value is the function that is used to update our state.

  const [devices,setDevices] = useState<VematDevicesTable[]>([]);
  const [modal,setModal] = useState<boolean>(false);

  const [formError, setFormError] = useState<Ierror[]>([]);
  //const[update,setUpdate] = useState(false);


  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource:{
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };


  //1. fetch data
  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier
  const rawSql = `SELECT address,name,coef FROM devices order by address desc`;
  const selectQuery = createQuery('now-1h', 'now', datasourceUID, rawSql);

   const headers = useMemo(() => {
    // Replace the following with your actual header initialization logic
    return {
      //Authorization: 'Bearer your_access_token',
      'Content-Type': 'application/json',
    };
  }, []);
  //2. onclick event handler
  //3. edit / delete
  const reloadTable = useCallback(()=>{
    fetchData(grafanaApiBaseUrl, method, headers, selectQuery)
    .then(data => {
      if (data) {

        const ids = data.results.A.frames[0].data.values[0];
        const names = data.results.A.frames[0].data.values[1];
        const coefs = data.results.A.frames[0].data.values[2];

        const mappedData = ids.map((id: number, index: number) => ({
          id,
          name: names[index],
          coef: coefs[index],
          edited: false,
          showError:false,
        }));
        //map error array 
        const errList: Ierror[]=[];
        for(let i = 0 ; i < mappedData.length ; i++ ){
          errList.push({id:mappedData[i].id,message:"",show:false});
        }
        setFormError(errList);
        setDevices(mappedData);
        //napamovani nactenych dat do StateVariable
      }
    });
  }, [grafanaApiBaseUrl, method, headers, setDevices, setFormError,selectQuery]);
  //data fetching and processing
  useEffect(() => {
   reloadTable();
   return () => {
    // Cleanup code here
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handleSave = (index: number, row: VematDevicesTable) => {

    const newData = [...devices];
    const errorData = [...formError]

  // Perform validation
  if (!row.name.trim()) {
    // Error when name field is empty
    errorData[index].show = true;
    errorData[index].message = "Chyba v názvu";
    setFormError(errorData);
   // setFormError(prevState => ({...prevState, name: 'Name cannot be empty'}));
   // newData[index]["showError"]=true;
    //errorData[index][""]
    return;  // return early
  } else if (isNaN(Number(row.coef))) {
    // Error when coef is not a number
    errorData[index].show = true;
    errorData[index].message = "Chyba v koeficientu";
    setFormError(errorData);
   // setFormError(prevState => ({...prevState, coef: 'Coefficient should be a number'}));
    return;  // return early
  }



  //do update in db
  const updateSql = `UPDATE devices SET name = '${row.name}',coef = '${row.coef == null? 0 : row.coef}' WHERE address = ${row.id}`;
  const updateQuery = createQuery('now-1h', 'now', datasourceUID, updateSql);
  console.log(updateSql);
  fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
  newData[index].edited=false;
  setDevices(newData);
    //const newErrors = [...errors];
   // newErrors[index].message = editedMessage;
   // setErrors(newErrors);
   // setEditingError(null);

    
console.log(row);
  };


  /*
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedMessage(event.target.value);
  };
*/
const handleChange = (event: React.FormEvent<HTMLInputElement>, id: number, field: string) => {


  const newData = [...devices];
  newData[id][field] = event.currentTarget.value;
  newData[id]["edited"]=true;
  const errs = [...formError];
  errs[id].show=false;
  errs[id].message="";
  setFormError(errs);
  setDevices(newData);
};

  const handleDelete = (index: number, row: VematDevicesTable) => {
    setModal(false);
    //todo delete cascade from db
    const deleteSql = `DELETE FROM devices WHERE address = ${row.id}`;
    const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
    fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);
    const _devices = [...devices]
    _devices.splice(index,1);
    setDevices(_devices);
   // const updatedErrors = [...errors];
   // updatedErrors.splice(index, 1);
   // setErrors(updatedErrors);

  };
/*
  const handleAdd = () => {
    //console.log(errors[0].parameter);
    const variable = replaceVariables(options.variable);
    console.log("variable", variable);
    const insertSql = `INSERT INTO errors (param, error) VALUES ('${variable}', '${newErrorMessage}');`;
    console.log("insertSQL", insertSql);
    const insertQuery = createQuery('now-1h', 'now', datasourceUID, insertSql);
    console.log(insertSql);

    fetchData(grafanaApiBaseUrl, method, headers, insertQuery);
    
    
    console.log(variable);
    setErrors([...errors, { id: errors.length + 1, parameter: options.variable, message: newErrorMessage }]);
    setNewErrorMessage('');
  };
  */
  //funkce pro add -> jen nektere tabulky btw
  const table_theme = createTheme({
    overrides: {
      MuiFormControl:{
      root:{
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.primary.contrastText
      }
      },
      MuiInputBase:{
        input:{
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText
        }
      },
      MuiTableCell: {
        body:{backgroundColor: theme.colors.background.secondary,
          borderBottom: "0px solid rgba(224, 224, 224, 1)",
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText},
        head:{backgroundColor: theme.colors.background.secondary,
          borderBottom: "0px solid rgba(224, 224, 224, 1)",
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText},
        root: {
           backgroundColor: theme.colors.background.secondary,
           borderBottom: "0px solid rgba(224, 224, 224, 1)",
           fontFamily: theme.typography.fontFamily,
           fontSize: theme.typography.fontSize,
           color: theme.colors.primary.text
         }
       },
     },
  });
  return (

    <ThemeProvider theme={table_theme}>
      <CustomScrollbar>
    <TableContainer component={Paper} style={{backgroundColor: theme.colors.background.primary}}>
    
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Adresa</TableCell>
          <TableCell>Název</TableCell>
          <TableCell>Koeficient</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {devices.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Input
               disabled={true}
                value={row.id}
                
              />
            </TableCell>
            <TableCell>
              <Input
                value={row.name}
                onChange={(e) => handleChange(e, index, 'name')}
                invalid={Boolean(formError[index].show)}
                //helperText={formError[index].message}
              />

              
            </TableCell>
            <TableCell>
              <Input
                value={row.coef == null ? 0 : row.coef}
                onChange={(e) => handleChange(e, index, 'coef')}
                invalid={Boolean(formError[index].show)}
                 // helperText={formError[index].message}
               
              />
            </TableCell>
            
          <>
        {row.edited  &&
         <TableCell>
           <Button onClick={() => handleSave(index, row)} variant={"primary"}>Save</Button>
           </TableCell>
       }</>
                 
                 <TableCell>

                 <Button size="md" variant='destructive' onClick={() => setModal(true)} >Smazat</Button>
                <ConfirmModal isOpen={modal} title={"Smazat zařízení z databáze"} body={"Opravdu chcete smazat zařízení z databáze?"} description={"Zařízení bude smazáno i s jeho záznamy."} confirmText={"Smazat"} confirmButtonVariant={"primary"} dismissText={"Zrušit"} icon={"exclamation-triangle"} onConfirm={()=>handleDelete(index,row)} onDismiss={()=>setModal(!modal)} />

                  
                     
                  
              
                
                
                  
             
            </TableCell>
                

          </TableRow>
        ))}
      </TableBody>
    </Table>
   
  </TableContainer>
  </CustomScrollbar>
  </ThemeProvider>
  );
};
